.breadcrumb-item {
    font-size: 1.25rem; /* Make the text bigger */
  }
  
  .breadcrumb-item {
    color: blue; /* Make the text blue */
  }
  
  .breadcrumb-item.active {
    color: gray; /* Make the current item gray */
    pointer-events: none; /* Disable pointer events for the active item */
  }